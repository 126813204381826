<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData.user_service_plan_item"
    ></WsReadSection>
    <WsCrud
      v-if="modelData.user_service_plan_item"
      class="mt-40"
      :modelName="$store.state.stone_model.user_service_plan_record.modelName"
      :label="$store.state.stone_model.user_service_plan_record.label"
      :fields="$store.state.stone_model.user_service_plan_record.fields"
      :showFields="showFields.user_service_plan_record"
      :infiniteScroll="true"
      :params="_params"
      :creatable="false"
      :updatable="false"
      :deletable="false"
      :expandable="false"
      :showExpand="false"
    ></WsCrud>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/user_service_plan_item";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: [
      "user",
      "service_plan",
      "service_plan_item",
      "content",
      "total_count",
      "remain_count",
    ],
    rightFields: ["id"],
    showFields: {
      user_service_plan_record: ["count", "remark", "created_at", "admin"],
    },
    modelData: {
      user_service_plan_item: "",
    },
  }),

  computed: {
    _params() {
      return {
        user_service_plan_item: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>